import React from "react";
import TagIcon from "@/icons/tag";

const OffersBadge = ({ title }: { title: string | undefined }) => {
  if (!title) return null;
  return (
    <div
      className="absolute right-6 top-6 flex items-center justify-center gap-2 rounded-lg p-2 text-sm font-bold text-white"
      style={{ backgroundColor: "#7EB4DB" }}
    >
      <TagIcon width={16} height={16} className="mt-1" />
      <p className="font-bold">{title}</p>
    </div>
  );
};

export default OffersBadge;
