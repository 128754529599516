"use client";
import React, { useState } from "react";

import PopularRoute from "./popular-route";
import styles from "./popular-routes.module.css";

import { Routes } from "../../types";
import { Translations } from "@/global-types/translations";
import ArrowUpCircleIcon from "@/icons/arrow-up-circle";
import ArrowDownCircleIcon from "@/icons/arrow-down-circle";

type Props = {
  data: Routes;
  translations: Translations | undefined;
};

const VisibileRoutes = ({ data, translations }: Props) => {
  const [visibleCount, setVisibleCount] = useState(6);
  const totalRoutes = Object.entries(data.routes_items).length;

  const Icon =
    visibleCount >= totalRoutes ? ArrowUpCircleIcon : ArrowDownCircleIcon;

  const onButtonClick = () => {
    if (visibleCount >= totalRoutes) {
      setVisibleCount(6);
    } else {
      setVisibleCount(totalRoutes);
    }
  };

  return (
    <div className={styles.wrapper}>
      {Object.entries(data.routes_items).map(([key, route], index) => (
        <div key={key} className={index > visibleCount ? "hidden" : "block"}>
          <PopularRoute route={route} translations={translations} />
        </div>
      ))}
      <div className="cursor-pointer self-center" onClick={onButtonClick}>
        <Icon
          width={32}
          height={32}
          strokeWidth={1.5}
          className="stroke-accent"
        />
      </div>
      <div className={styles.actions}>
        <a className="btn btn-accent" href={data.routes_call_to_action_link}>
          {data.routes_call_to_action_label}
        </a>
      </div>
    </div>
  );
};

export default VisibileRoutes;
